import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  TransportJob,
  Address,
  Delivery,
  Pickup,
  ItemSet,
  TransportJobAccountLink,
  StopDetails,
} from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";
import { Spacer, IconLoader, Message } from "@brenger/react";
import cn from "classnames";

import {
  coreClient,
  CacheKey,
  formatLocality,
  formatSecondsToHoursMinutes,
  JobPresentation,
  isComboJob,
  getCommittedDtpsForStop,
  isDriverPayout,
} from "../../utils";
import {
  TransportJobDetails,
  StopCard,
  VolumeDetails,
  MetaItem,
  ItemDetails,
  StopDetailPills,
  StopConfirmedAtPill,
} from "..";
import { useTranslation, useAuth } from "../../hooks";
import { StopConfirmedPill } from "./StopConfirmedPill";

interface Props {
  tj: TransportJob;
  presentation: JobPresentation;
  /**
   * This becomes available in the context of the planning & delivered sections, ie,
   * the point at which a driver has made a link to the TJ.
   */
  tjal?: TransportJobAccountLink | null;
}

export const getStopDetails = (pickups: Pickup[], deliveries: Delivery[]): StopDetails[] => {
  const pickupsDetails = pickups.map((s) => (s as Pickup).details);
  const deliveriesDetails = deliveries.map((s) => (s as Delivery).details);

  const tjalDetails = [...pickupsDetails, ...deliveriesDetails];
  return tjalDetails;
};

/**
 * A contrived convenience method for parsing + concat'ing item set titles.
 */
const parseItemSetTitles = (itemSets: ItemSet[]): string => {
  return itemSets
    .map((itemSet) => itemSet.title)
    .filter(Boolean)
    .join(" & ");
};

export const BundledJobSummary: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const routeId = getIdFromIri(props.tj.transport_route);
  const route = useQuery(
    [CacheKey.RETRIEVE_ROUTE, routeId],
    () => coreClient.routes.retrieve({ id: routeId as string }),
    {
      enabled: !!routeId,
    }
  );

  if (route.isLoading) {
    return (
      <div className={cn("flex", "justify-center", "items-center", "flex-grow")}>
        <IconLoader className={cn("w-6", "h-6")} />
      </div>
    );
  }

  if (route.error) {
    return <Message type="error">{(route.error as Error)?.message}</Message>;
  }

  // The distance of the TJ route isn't the most ideal. The last stop of route is better.
  const distance = route.data?.stops[route.data?.stops.length - 1].distance_km;
  const tjalDetails = getStopDetails(props.tj.pickups, props.tj.deliveries);
  return (
    <div>
      <TransportJobDetails
        // Only account admins can see price
        price={auth.isAccountAdmin ? props.tj.total_payout : undefined}
        isPrepaid={props.tj.directly_claimable}
        isBundled={props.tj.bundled}
        isComboJob={isComboJob(props.tj)}
        isBusiness={props.tj.business}
        rewardTime={formatSecondsToHoursMinutes(props.tj.rewarded_working_time)}
        maxVolume={props.tj.max_volume_m3_inflated}
        distance={distance}
        itemSets={props.tj.item_sets}
        all_stop_details={{
          carrying_help: tjalDetails.map((f) => f?.carrying_help),
          situation: tjalDetails.map((s) => s.situation),
        }}
        presentation={props.presentation}
        /**
         * Only care about individual payouts (the product payment is of no concern here)
         */
        driverPayouts={auth.isAccountAdmin ? props.tjal?.payouts.filter((p) => isDriverPayout(p)) : undefined}
      />
      <Spacer h={4} />
      {route.data?.stops.map((stop, idx) => {
        if (stop.pickup) {
          // Pluck out the pickup from the TJ
          const pickup = props.tj.pickups.find((p) => p["@id"] === stop.pickup);
          if (pickup) {
            const committedDtps = getCommittedDtpsForStop(pickup, props.tjal);
            // Either show the committments or the proposed DTPs
            const dateTimePeriods = committedDtps || [stop.proposed_datetime_period];
            const pickupAddress = pickup.address as Address;
            const pickupItemSets = props.tj.item_sets.filter((itemSet) => {
              return (itemSet.pickup as Pickup)["@id"] === pickup["@id"];
            });

            return (
              <StopCard
                key={idx}
                id={pickup["@id"]}
                title={<MetaItem name={t((d) => d.transport_job.pickup)} value={parseItemSetTitles(pickupItemSets)} />}
                stopNumber={stop.index + 1}
                distance={stop.distance_km}
                items={<ItemDetails itemSets={pickupItemSets} />}
                volume={
                  <VolumeDetails
                    total={stop.total_volume_m3_inflated}
                    delta={stop.delta_volume_m3_inflated}
                    type="Pickup"
                  />
                }
                details={<StopDetailPills details={pickup.details} itemSets={pickupItemSets} />}
                stopConfirmed={<StopConfirmedPill itemSets={pickupItemSets} stopType="Pickup" />}
                stopConfirmedAt={
                  <StopConfirmedAtPill stopType="Pickup" itemSets={pickupItemSets} committedDtps={committedDtps} />
                }
                dateTimePeriods={dateTimePeriods}
                locality={formatLocality(pickupAddress.locality, pickupAddress.administrative_area)}
                // NOTE: It's important to show the a teaser of the instructions on the stop cards in the search job details view
                // so that drivers are made aware of any added requests customer have or try to sneak in when creating a TR.
                instructions={props.presentation === "search" ? pickup.details.instructions : undefined}
                isFlexibleDates={props.tj.is_flexible_dates}
              />
            );
          }
        }

        if (stop.delivery) {
          const delivery = props.tj.deliveries.find((d) => d["@id"] === stop.delivery);
          // Pluck out the delivery from the TJ
          if (delivery) {
            // Get the "latest" driver commitment for the stop.
            const committedDtps = getCommittedDtpsForStop(delivery, props.tjal);
            // Either show the committments or the proposed DTPs
            const dateTimePeriods = committedDtps || [stop.proposed_datetime_period];
            const deliveryAddress = delivery?.address as Address | undefined;
            const deliveryItemSets = props.tj.item_sets.filter((itemSet) => {
              return (itemSet.delivery as Delivery)["@id"] === delivery["@id"];
            });

            return (
              <StopCard
                key={idx}
                title={
                  <MetaItem name={t((d) => d.transport_job.delivery)} value={parseItemSetTitles(deliveryItemSets)} />
                }
                id={delivery["@id"]}
                stopNumber={stop.index + 1}
                distance={stop.distance_km}
                details={<StopDetailPills details={delivery.details} itemSets={deliveryItemSets} />}
                volume={
                  <VolumeDetails
                    total={stop.total_volume_m3_inflated}
                    delta={stop.delta_volume_m3_inflated}
                    type="Delivery"
                  />
                }
                items={<ItemDetails itemSets={deliveryItemSets} />}
                stopConfirmed={<StopConfirmedPill stopType="Delivery" itemSets={deliveryItemSets} />}
                stopConfirmedAt={
                  <StopConfirmedAtPill stopType="Delivery" committedDtps={committedDtps} itemSets={deliveryItemSets} />
                }
                dateTimePeriods={dateTimePeriods}
                locality={formatLocality(deliveryAddress?.locality, deliveryAddress?.administrative_area)}
                // See note above!
                instructions={props.presentation === "search" ? delivery.details.instructions : undefined}
                isFlexibleDates={props.tj.is_flexible_dates}
              />
            );
          }
        }
      })}
    </div>
  );
};
