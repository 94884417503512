import { Address, DateTimePeriod, Item, RouteMeta, TransportJob, TransportJobAccountLink } from "@brenger/api-client";
import { Spacer } from "@brenger/react";
import * as React from "react";

import { StopCard, StopConfirmedAtPill, StopConfirmedPill, StopDetailPills, TransportJobDetails } from "..";
import { useAuth } from "../../hooks";
import {
  dtpsFilterAvailable,
  formatLocality,
  getCommittedDtpsForStop,
  isDriverPayout,
  JobPresentation,
} from "../../utils";

interface Props {
  tj: TransportJob;
  /**
   * This becomes available in the context of the planning & delivered sections, ie,
   * the point at which a driver has made a link to the TJ.
   */
  tjal?: TransportJobAccountLink | null;
  /**
   * Display different date time periods depending on the presentation view.
   */
  presentation: JobPresentation;
}

/**
 * NOTE: There is only ONE pickup and ONE delivery in the context of a regular stop.
 * Therefore, simply grab the first pickup and first delivery for the respective lists.
 */
export const RegularJobSummary: React.FC<Props> = (props) => {
  const auth = useAuth();
  /**
   * Massage out PICKUP periods.
   */
  const [pickup] = props.tj.pickups;
  const pickupCommittedDtps = getCommittedDtpsForStop(pickup, props.tjal);
  const pickupAddress = pickup.address as Address;
  const availablePickupPeriods = dtpsFilterAvailable({
    dtps: pickup.available_datetime_periods,
    isFlexibleDates: props.tj.is_flexible_dates,
  }) as DateTimePeriod[];
  // In the search section, the available periods are shown.
  // The the other sections, the committments are shown.
  const pickupPeriods = pickupCommittedDtps || availablePickupPeriods;

  /**
   * Massage out DELIVERY periods.
   */
  const [delivery] = props.tj.deliveries;
  const deliveryCommittedDtps = getCommittedDtpsForStop(delivery, props.tjal);
  const deliveryAddress = delivery.address as Address;
  const availableDeliveryPeriods = dtpsFilterAvailable({
    dtps: delivery.available_datetime_periods,
    isFlexibleDates: props.tj.is_flexible_dates,
  }) as DateTimePeriod[];
  const deliveryPeriods = deliveryCommittedDtps || availableDeliveryPeriods;
  const items = props.tj.item_sets.flatMap((is) => is.items as Item[]);

  return (
    <div>
      <TransportJobDetails
        // Only account admins can see price
        price={auth.isAccountAdmin ? props.tj.total_payout : undefined}
        isPrepaid={props.tj.directly_claimable}
        isBusiness={props.tj.business}
        items={items}
        maxVolume={props.tj.max_volume_m3_inflated}
        distance={(props.tj.route as RouteMeta)?.distance}
        itemSets={props.tj.item_sets}
        presentation={props.presentation}
        /**
         * Only care about individual payouts (the product payment is of no concern here)
         */
        driverPayouts={auth.isAccountAdmin ? props.tjal?.payouts.filter((p) => isDriverPayout(p)) : undefined}
      />
      <Spacer h={4} />
      <StopCard
        id={pickup["@id"]}
        stopNumber={1}
        stopConfirmed={<StopConfirmedPill stopType="Pickup" itemSets={props.tj.item_sets} />}
        stopConfirmedAt={
          <StopConfirmedAtPill stopType="Pickup" committedDtps={pickupCommittedDtps} itemSets={props.tj.item_sets} />
        }
        // Use the latest confirmation timestamp as a representation for the entire stop.
        details={<StopDetailPills details={pickup.details} itemSets={props.tj.item_sets} />}
        dateTimePeriods={pickupPeriods}
        locality={formatLocality(pickupAddress.locality, pickupAddress.administrative_area)}
        // NOTE: It's important to show the a teaser of the instructions on the stop cards in the search job details view
        // so that drivers are made aware of any added requests customer have or try to sneak in when creating a TR.
        instructions={props.presentation === "search" ? pickup.details.instructions : undefined}
        isFlexibleDates={props.tj.is_flexible_dates}
      />
      <StopCard
        id={delivery["@id"]}
        stopNumber={2}
        details={<StopDetailPills details={delivery.details} itemSets={props.tj.item_sets} />}
        stopConfirmed={<StopConfirmedPill stopType="Delivery" itemSets={props.tj.item_sets} />}
        stopConfirmedAt={
          <StopConfirmedAtPill
            stopType="Delivery"
            committedDtps={deliveryCommittedDtps}
            itemSets={props.tj.item_sets}
          />
        }
        dateTimePeriods={deliveryPeriods}
        locality={formatLocality(deliveryAddress.locality, deliveryAddress.administrative_area)}
        // See note above.
        instructions={props.presentation === "search" ? delivery.details.instructions : undefined}
        isFlexibleDates={props.tj.is_flexible_dates}
      />
    </div>
  );
};
